import {
	MainContainer,
	ChatContainer,
	MessageList,
	Message,
} from "@chatscope/chat-ui-kit-react";
import { Dialogue } from "../lib/Dialogue";

interface DialogueProps {
    endIndex: number;
    dialogues: Dialogue[];
}

const DialogueContainer: React.FC<DialogueProps> = ({endIndex, dialogues}: DialogueProps) => {
    const dialogue_length = endIndex === -1? dialogues?.length : endIndex;

	return (
		<div className="dialogue-content-container">
			<MainContainer>
				<ChatContainer>
					<MessageList>

                    {
                        dialogues?.map((dialogue: Dialogue, index) => {
                            if (index >= dialogue_length) {
                                return ;
                            }
                            return (
                                <div key={index}>
                                <span className="username">{dialogue.role}</span>
                                <Message
                                    model={{
                                        direction: "incoming",
                                        position: "normal",
                                        message: dialogue.sentence,
                                        sender: dialogue.role,
                                    }}
                                    onClick={() => console.log("message clicked")} // TODO
                                />
                                </div>
                            );
                        })
                        
                    }
					</MessageList>
				</ChatContainer>
			</MainContainer>
		</div>
	);
};

export default DialogueContainer;
