import { useEffect, useCallback, useState } from "react";
import NavigationBar from "./NavigateBar";
import SideNavMenu from "./SideNavMenu";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import StoryFlipCard from "./StoryFlipCard";
import StoryListContainer from "./StoryListContainer";
import { StoryGroup, StorySlice, Translation } from "../lib/Story";
import { Navigate } from "react-router-dom";
import { FiPause, FiPlay } from "react-icons/fi";
import PopupModal from "./StoryPopupModal";
import { useAppSelector } from "../store/hooks";

const LANGUAGE = "Chinese";

interface props {
	isAuthentic: boolean;
}

const EnLearnStory: React.FC<props> = ({ isAuthentic }: props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [curLevel, setCurLevel] = useState<number>(0);
	const [storyList, setStoryList] = useState<StoryGroup[]>([]);
	const [indexAtLevel, setIndexAtLevel] = useState<number>(0);
	const [topWordsTrans, setTopWordTrans] = useState<Translation>({});
	const [paused, setPaused] = useState<boolean>(false);

	const newStoryNum = useAppSelector((state) => state.user.story_new);
	const uid = useAppSelector((state) => state.user.uid);

	const modifyStoryList = async (lv: number) => {
		setIsLoading(true);
		try {
			const uri = `/en_learn/story?lv=${lv}`;
			const response = await fetch(uri);
			const data = (await response.json()) as StoryGroup[];
			await getTranslations(data[0]);
			setStoryList(data);
		} catch (error) {
			console.log(error);
		}

		setCurLevel(lv);
		setIsLoading(false);
		setIndexAtLevel(0);
	};

	const getTranslations = async (story: StoryGroup) => {
		const translations: Translation = {};

		story.story.forEach((slice) => {
			slice.top.forEach(async (word) => {
				const uri = `/en_learn/get_translate?lang=${LANGUAGE}&w=${word}`;
				const response = await fetch(uri);
				const data = await response.json();

				translations[data.word] = data[LANGUAGE];
			});
		});

		setTopWordTrans(translations);
	};

	useEffect(() => {
		const initialize = async () => {
			await modifyStoryList(0);
		};

		initialize();
	}, []);

	const selectIndexHandler = useCallback(
		async (index: number) => {
			// TODO: retrieve audio
			await getTranslations(storyList[index]);
			setIndexAtLevel(index);
		},
		[topWordsTrans]
	);

	const selectLevelHandler = useCallback(async (lv: number) => {
		// TODO: encrypt index
		await modifyStoryList(lv);
	}, []);

	const updateCurIndexHandler = (flag: boolean) => {
		if (flag) {
			const new_index =
				indexAtLevel + 1 >= storyList.length
					? indexAtLevel
					: indexAtLevel + 1;
			setIndexAtLevel(new_index);
		} else {
			const new_index = indexAtLevel - 1 < 0 ? 0 : indexAtLevel - 1;
			setIndexAtLevel(new_index);
		}
	};

	const onCreateStoryHandler = useCallback(
		async (text: string, lv: number) => {
			console.log("creating new story");

			await modifyStoryList(lv);

			setIsLoading(true);
			const n = storyList.length;

			console.log("current story list lenght: ", n);

			const uri = `/en_learn/new_story?t=${text}&k=${newStoryNum}&lv=${lv}&uid=${uid}`;
			const response = await fetch(uri);

			if (!response.ok || !response.body) {
				throw response.statusText;
			}

			const reader = response.body.getReader();

			const decoder = new TextDecoder();
			let flag: boolean = true;
			let newStoryObj: StoryGroup = {
				title: "",
				created_by: uid,
				created_at: "",
				story: [],
				lv: 0,
				score: 0,
			};
			let beginObj = { story: [] as StorySlice[] };
			let countIn = 0;

			while (true) {
				const { done, value } = await reader.read();
				if (done) {
					console.log("All story created.");
					break;
				}

				const data = decoder.decode(value, { stream: true });

				if (data === "\n") {
					newStoryObj = {
						title: "",
						created_by: uid,
						created_at: "",
						story: [],
						lv: 0,
						score: 0,
					};

					beginObj = { story: [] as StorySlice[] };
					countIn = 0;
				} else {
					const json = JSON.parse(data);

					if (countIn < 2) {
						beginObj = { ...beginObj, ...json };
					} else if (flag && countIn === 2) {
						setIndexAtLevel(n);
						flag = false;
						const storySlice: StorySlice = json as StorySlice;
						newStoryObj = beginObj as StoryGroup;
						newStoryObj["story"].push(storySlice);

						setStoryList([...storyList, newStoryObj]);
						setIsLoading(false);
					} else {
						const storySlice: StorySlice = json as StorySlice;
						newStoryObj["story"].push(storySlice);

						setStoryList([...storyList, newStoryObj]);
					}

					countIn++;
				}
			}
		},
		[storyList]
	);

	return (
		<>
			{isAuthentic ? (
				<div className="page-container">
					<NavigationBar />

					<div className="content-container">
						<SideNavMenu location="stories" />
						<div className="content-card">
							{isLoading && <Spinner animation="border" />}
							{!isLoading && (
								<StoryFlipCard
									index={indexAtLevel}
									translations={topWordsTrans}
									content={storyList[indexAtLevel]}
									isLoading={isLoading}
								/>
							)}

							{!isLoading && (
								<div className="content-dialogue-button">
									<PopupModal
										onCreateStoryHandler={
											onCreateStoryHandler
										}
									/>
									<Button
										style={{
											justifySelf: "start",
											marginRight: "auto",
											cursor: "pointer",
										}}
										onClick={() =>
											updateCurIndexHandler(false)
										}>
										Prev
									</Button>
									<div>
										{paused && (
											<FiPlay
												className="button-hover"
												size={35}
												onClick={() => setPaused(false)}
											/>
										)}
										{!paused && (
											<FiPause
												className="button-hover"
												size={35}
												onClick={() => setPaused(true)}
											/>
										)}
									</div>
									<Button
										style={{
											justifySelf: "end",
											marginLeft: "auto",
											marginRight: "55px",
										}}
										onClick={() =>
											updateCurIndexHandler(true)
										}>
										Next
									</Button>
								</div>
							)}
						</div>
						<div className="control-list">
							<StoryListContainer
								isLoading={isLoading}
								curIndex={curLevel}
								items={storyList}
								cardIndex={indexAtLevel}
								onItemSelected={selectLevelHandler}
								onCardSelected={selectIndexHandler}
							/>
						</div>
					</div>
				</div>
			) : (
				<Navigate
					replace
					to="/account/login"
				/>
			)}
		</>
	);
};

export default EnLearnStory;
