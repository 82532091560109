import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import Alert from "react-bootstrap/Alert";
import NavigationBar from "./NavigateBar";
import { signUp } from "../actions/auth"; 

interface IFormInput {
	email: string;
	userName: string;
	password: string;
	password2: string;
}

const Register: React.FC = () => {
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IFormInput>();
	const [errorMsg, setErrorMsg] = useState<string>("");
    const dispatch = useAppDispatch();

	const onSubmit: SubmitHandler<IFormInput> = async (data) => {
		const email = data.email;
		const password = data.password;
		const username = data.userName;
        try {
            const action = signUp({username, email, password});
            await dispatch(action)
			navigate("/en_learn");
		} catch (error) {
			console.log(error);

			setErrorMsg("User Already Exists.");

			setTimeout(() => {
				setErrorMsg("");
			}, 2000);
		}
	};

	const navigate = useNavigate();

	return (
		<>
			<NavigationBar />
			<div className="login_form">
				<form
					className="login_form"
					onSubmit={handleSubmit(onSubmit)}>
					<label className="login_label">Email</label>
					<input
						className="login_input"
						{...register("email", {
							required: "Email is required.",
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
								message:
									"Invalid Email address. Please enter a valid Email.",
							},
						})}
					/>
					{errors?.email && (
						<p className="invalid_p">{errors.email.message}</p>
					)}

					<label className="login_label">User Name</label>
					<input
						className="login_input"
						{...register("userName", {
							required: "UserName is required.",
						})}
					/>
					{errors?.userName && (
						<p className="invalid_p">{errors.userName.message}</p>
					)}

					<label className="login_label">Password</label>
					<input
						type="password"
						className="login_input"
						{...register("password", {
							required: "Password is required",
							minLength: {
								value: 6,
								message:
									"Password is required to have at lease 6 characters.",
							},
						})}
					/>
					{errors?.password && (
						<p className="invalid_p">{errors.password.message}</p>
					)}

					<label className="login_label">Renter Password</label>
					<input
						type="password"
						className="login_input"
						{...register("password2", {
							required: "Please reenter your password.",
							minLength: {
								value: 6,
								message:
									"Password is required to have at lease 6 characters.",
							},
							validate: (value) =>
								getValues("password") ===
									getValues("password2") ||
								"Two password must be the same.",
						})}
					/>
					{errors?.password2 && (
						<p className="invalid_p">{errors.password2.message}</p>
					)}

					<div className="login_buttons">
						<input
							className="login_submit"
							style={{
								justifySelf: "start",
								marginRight: "auto",
							}}
							type="submit"
							value="Register"
						/>

						<button
							className="login_submit"
							style={{
								justifySelf: "end",
								marginLeft: "auto",
							}}
							type="button"
							onClick={() => navigate("/account/login")}>
							{" "}
							Login{" "}
						</button>
					</div>
				</form>
				{errorMsg && <Alert variant="warning">{errorMsg}</Alert>}
			</div>
		</>
	);
};

export default Register;
