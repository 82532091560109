import React, { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import { StoryGroup } from "../lib/Story";

interface StoryListProps {
	isLoading: boolean;
	curIndex: number; // Group Index
	cardIndex: number; // Card Index at group
	items: StoryGroup[];
	onItemSelected: (index: number) => Promise<void>;
    onCardSelected: (index: number) => Promise<void>;
}

const StoryListContainer: React.FC<StoryListProps> = ({
	isLoading,
	curIndex,
	items,
	cardIndex,
	onItemSelected,
    onCardSelected
}: StoryListProps) => {
	const accordElem = useRef<HTMLDivElement[]>([]);
	const cardElem = useRef<HTMLDivElement[]>([]);
	const [activeKey, setActiveKey] = useState<string>(String(curIndex));
	var cardIndexCnt = 0;
    const lv_lst = [0, 1, 2] // currently have only 3 levels

	useEffect(() => {

		//Handle Scroll here when opening
		if (String(curIndex) !== activeKey) {
			setActiveKey(String(curIndex));
		
			//Handle Scroll here when opening
			setTimeout(() => {
				if (accordElem.current[curIndex] != null) {
					accordElem.current[curIndex].scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "start",
					});
				}
			}, 400);
		} else {

			setTimeout(() => {
				let index: number = cardIndex;

				if (cardElem.current[index] != null) {
					cardElem.current[index].scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "start",
					});
				}
			}, 400);
		}
	}, [cardIndex, curIndex, activeKey])

	const handleClickToggle = (index: number) => {
		if (String(index) === activeKey) {
			setActiveKey("");
		} else {
            setActiveKey(String(index))
        }
	};

	return (
		<Accordion
			style={{ textAlign: isLoading ? "center" : "inherit" }}
			activeKey={activeKey}>
			{lv_lst.map((lv, index) => {

				return (
					<Accordion.Item
						key={lv}
						eventKey={String(index)}
						ref={(el: HTMLDivElement) =>
							(accordElem.current[lv] = el)
						}>
						<Accordion.Header
							onClick={() => {
								if (String(lv) !== activeKey) {
									onItemSelected(lv);
								}
								handleClickToggle(lv);
							}}>
							Level {lv + 1}
						</Accordion.Header>
						<Accordion.Body>
							{ activeKey === String(index) && items.map((item, it_index) => {
								return (
									<Card
										style={{
											backgroundColor:
												curIndex === index &&
												it_index === cardIndex
													? "#cfe2ff"
													: "inherit",
										}}
										key={it_index}
										className="story-list-card"
										ref={(el: HTMLDivElement) => (cardElem.current[cardIndexCnt++] = el)}
                                        onClick={() => onCardSelected(it_index)}
                                        >
										{item.title.length > 32? item.title.substring(7, 32) + "..." : item.title.substring(7)}
									</Card>
								);
							})}
						</Accordion.Body>
					</Accordion.Item>
				);
			})}
			{isLoading && (
				<Spinner
					animation="border"
					size="sm"
				/>
			)}
		</Accordion>
	);
};

export default StoryListContainer;
