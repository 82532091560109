import SideNavMenu from "./SideNavMenu";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import NavigationBar from "./NavigateBar";
import { useCallback, useEffect, useState } from "react";
import { DialogueSet } from "../lib/Dialogue";
import { useLRUCache } from "../lib/LRUCache";
import { FiPause, FiPlay } from "react-icons/fi";
import DialogueListContainer from "./DialogueListContainer";
import DialogueContainer from "./DialogueContainer";
import PopupModal from "./DialoguePopupModal";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";

interface props {
	isAuthentic: boolean;
}

const EnLearnDialogue: React.FC<props> = ({ isAuthentic }: props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [curIndex, setCurIndex] = useState<number>(0); // Index of selected item
	const [indexAtDialogue, setIndexAtDialogue] = useState<number>(-1); // set to -1 for testing
	const [updateInterval, setUpdateInterval] = useState<number>(0);
	const [dialogueList, setDialogueList] = useState<DialogueSet[]>([]);
	const [paused, setPaused] = useState(false);
	const { get, put, getCachedData } = useLRUCache<DialogueSet>(8);
	const num_new_dialogue = useAppSelector(state => state.user.dialogue_new);
	const uid = useAppSelector(state => state.user.uid);

	const resetInterval_IndexAtDialogue = () => {
		setIndexAtDialogue(-1);
		setUpdateInterval(0);
		setPaused(false);
	};

	const constructDialogueList = (n: number) => {
		const lst: DialogueSet[] = [];

		for (let i = 0; i < n; i++) {
			let content: DialogueSet = {
				index: i,
				created_at: "",
				created_by: uid,
				scene: "",
				dialogue: [],
			};
			const contentInCache = get(i);
			if (contentInCache !== null) {
				content = contentInCache;
			}

			lst.push(content);
		}
		setDialogueList(lst);
	};

	const modifyDialogueList = async (index: number, init: boolean = false) => {
		if (init || !getCachedData().includes(index)) {
			setIsLoading(true);
			let n: number = 0;
			try {
				const response = await fetch("/en_learn/total_dialogue");
				const data = await response.json();
				n = parseInt(data);

				const dialogueRes = await fetch(
					`/en_learn/dialogues?s=${index}&e=${index + 4}`
				);
				const contents = (await dialogueRes.json()) as DialogueSet[];

				contents.forEach((data) => {
					put(data.index, data);
				});
			} catch (error) {
				console.log(error);
			}

			constructDialogueList(n);
			// Set to -1 for testing
			resetInterval_IndexAtDialogue();
			setIsLoading(false);
		}

		setCurIndex(index);
	};

	useEffect(() => {
		const initializeState = async () => {
			try {
				modifyDialogueList(curIndex, true);
			} catch (error) {
				console.log(error);
			}
		};

		initializeState();
	}, []);

	useEffect(() => {
		if (!paused && indexAtDialogue !== -1) {
			const new_interval: number =
				dialogueList[curIndex]?.dialogue[indexAtDialogue]?.sentence
					.length * 100;
			const id = setInterval(
				() => setIndexAtDialogue(indexAtDialogue + 1),
				updateInterval
			);
			setUpdateInterval(new_interval);

			return () => {
				clearInterval(id);
			};
		}
	}, [indexAtDialogue, paused]);

	const selectHandler = useCallback(async (index: number) => {
		modifyDialogueList(index);
	}, []);

	const updateCurIndexHandler = async (type: boolean) => {
		// next
		if (type) {
			const newIndex: number =
				curIndex + 1 >= dialogueList.length ? curIndex : curIndex + 1;
			await modifyDialogueList(newIndex);
		} else {
			// prev
			const newIndex: number = curIndex - 1 < 0 ? curIndex : curIndex - 1;
			await modifyDialogueList(newIndex);
		}
	};

	const onCreateDialogueHandler = useCallback(async (text: string) => {
		setIsLoading(true);
		console.log("creating new dialogue");

		// let n = parseInt(
		// 	await (await fetch("/en_learn/total_dialogue")).json()
		// );

		let n = dialogueList.length;
		const response = await fetch(`/en_learn/new_dialogue?s=${text}&uid=${uid}&k=${num_new_dialogue}`);
		
		if (!response.ok || !response.body) {
			throw response.statusText;
		}

		const reader = response.body.getReader();

		const decoder = new TextDecoder();
		let flag = true;

		while (true) {
			const { done, value } = await reader.read();
			if (done) {
				console.log("All dialogues created.");
				break;
			};	
			
			const data = decoder.decode(value, { stream: true })
			const json = JSON.parse(data) as DialogueSet;

			put(n++, json)
			constructDialogueList(n);

			if (flag) {
				setCurIndex(n - 1);
				resetInterval_IndexAtDialogue();
				flag = false;
			}
			setIsLoading(false);
		}
		
		//setCurIndex(n - 2);
		//resetInterval_IndexAtDialogue();
		
	}, [dialogueList]);

	return (
		<>
			{isAuthentic ? (
				<div className="page-container">
					<NavigationBar />

					<div className="content-container">
						<SideNavMenu location="dialogues" />
						<div className="content-card">
							{isLoading && <Spinner animation="border" />}
							{!isLoading && (
								<DialogueContainer
									dialogues={dialogueList[curIndex]?.dialogue}
									endIndex={indexAtDialogue}
								/>
							)}

							{!isLoading && (
								<div className="content-dialogue-button">
									<PopupModal
										onCreateDialogueHandler={
											onCreateDialogueHandler
										}
									/>
									<Button
										style={{
											justifySelf: "start",
											marginRight: "auto",
											cursor: "pointer",
										}}
										onClick={() =>
											updateCurIndexHandler(false)
										}>
										Prev
									</Button>
									<div>
										{paused && (
											<FiPlay
												className="button-hover"
												size={35}
												onClick={() => setPaused(false)}
											/>
										)}
										{!paused && (
											<FiPause
												className="button-hover"
												size={35}
												onClick={() => setPaused(true)}
											/>
										)}
									</div>
									<Button
										style={{
											justifySelf: "end",
											marginLeft: "auto",
											marginRight: "55px",
										}}
										onClick={() =>
											updateCurIndexHandler(true)
										}>
										Next
									</Button>
								</div>
							)}
						</div>
						<div className="control-list">
							<DialogueListContainer
								isLoading={isLoading}
								items={dialogueList}
								curIndex={curIndex}
								onItemSelected={selectHandler}
								get={get}
							/>
						</div>
					</div>
				</div>
			) : (
				<Navigate
					replace
					to="/account/login"
				/>
			)}
		</>
	);
};

export default EnLearnDialogue;
