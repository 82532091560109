import { Navigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import NavigationBar from "./NavigateBar";
import SideNavMenu from "./SideNavMenu";
import { userSetting } from "../actions/auth";
import { useState } from "react";

interface props {
	isAuthentic: boolean;
}

interface IFormInput {
	dialogue_new_setting: number;
	story_word_setting: number;
	story_new_setting: number;
}

const Setting = ({ isAuthentic }: props) => {
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IFormInput>();

	const [msg, setMsg] = useState<string>("");
	const [status, setStatus] = useState<number>(200);
	const dispatch = useAppDispatch();
	const dialogue_new: number = useAppSelector(
		(state) => state.user.dialogue_new
	);
	const uid: string = useAppSelector((state) => state.user.uid);
	const story_top: number = useAppSelector((state) => state.user.story_top);
	const story_new: number = useAppSelector(state => state.user.story_new);
	const onSubmit: SubmitHandler<IFormInput> = async (data) => {
		const action = userSetting(uid, {
			dialogue_new: data.dialogue_new_setting.valueOf(),
			story_top: data.story_word_setting.valueOf(),
			story_new: data.story_new_setting.valueOf(),
		});
		const status = await dispatch(action);

		if (status === 200) {
			setMsg("Setting Saved.");
		} else {
			setMsg("Issue occurred during saving.");
		}

		setStatus(status);

		setTimeout(() => {
			setMsg("");
		}, 2000);
	};

	return (
		<>
			{isAuthentic ? (
				<div className="page-container">
					<NavigationBar />
					<div className="content-container">
						<SideNavMenu location="setting" />
						<div className="login_form">
							<form
								className="login_form"
								onSubmit={handleSubmit(onSubmit)}>
								<label className="login_label">
									Number of new dialogues will be created per
									request
								</label>
								<select
									className="setting-select"
									defaultValue={String(dialogue_new)}
									{...register("dialogue_new_setting")}>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>

								<label className="login_label">
									Number of hard words shown on each story
									section
								</label>
								<select
									defaultValue={String(story_top)}
									className="setting-select"
									{...register("story_word_setting")}>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
								</select>

								<label className="login_label">
									Number of new stories will be created per
									request
								</label>
								<select
									defaultValue={String(story_new)}
									className="setting-select"
									{...register("story_new_setting")}>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
								</select>
								<input
									className="login_submit"
									style={{ width: "90px" }}
									type="submit"
									value="Save"
								/>
							</form>
							{msg && (
								<Alert
									variant={
										status === 400 ? "warning" : "primary"
									}>
									{msg}
								</Alert>
							)}
						</div>
					</div>
				</div>
			) : (
				<Navigate
					replace
					to="/account/login"
				/>
			)}
		</>
	);
};

export default Setting;
