import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RootState } from "./store/store";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import Home from "./component/Home";
import Contact from "./component/Contact";
import EnLearnHome from "./component/EnLearnHome";
import EnLearnDialogue from "./component/EnLearnDialogue";
import EnLearnStory from "./component/EnLearnStory";
import Login from "./component/Login";
import Register from "./component/Register";
import { auth } from "./lib/firebase";
import { retrieveUser } from "./actions/auth";
import Setting from "./component/Setting";

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [uid, setUid] = useState<string>("");
	const dispatch = useAppDispatch();


	useEffect (() => {
		const retrieve_user = async () => {
			if (uid) {
				const action = retrieveUser({uid});
				await dispatch(action);
			}
		}

		retrieve_user()
	}, [uid])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if(user) {
				const token = await user.getIdTokenResult();
				const user_id = token.claims.user_id;
				console.log(token.expirationTime)
				if (typeof user_id === 'string') {
					setUid(user_id)
				}
				setIsAuthenticated(true);
			} else {


				setIsAuthenticated(false);
			}
		});
	}, [dispatch])


	return (
		<>
			<Router>
				<Routes>
					<Route
						path="/"
						element={<Home />}
					/>
					<Route
						path="/en_learn"
						element={<EnLearnHome isAuthentic={isAuthenticated} />}
					/>
					<Route
						path="/en_learn/dialogues"
						element={
							<EnLearnDialogue isAuthentic={isAuthenticated} />
						}
					/>
					<Route
						path="/en_learn/stories"
						element={<EnLearnStory isAuthentic={isAuthenticated} />}
					/>
					<Route
						path="/account/login"
						element={<Login isAuthentic={isAuthenticated} />}
					/>
					<Route
						path="/account/register"
						element={<Register />}
					/>
					<Route 
						path="/en_learn/setting"
						element={<Setting isAuthentic={isAuthenticated}/>}
					/>
					<Route
						path="/contact"
						element={<Contact />}
					/>
				</Routes>
			</Router>
		</>
	);
}

export default App;
