import { RiDiscussFill, RiDiscussLine } from "react-icons/ri";
import { GoHome } from "react-icons/go";
import { NavLink } from "react-router-dom";
import { TbBook, TbBook2, TbSettings, TbSettingsFilled } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { useAppDispatch } from "../store/hooks";
import { logOut } from "../actions/auth";


interface NavProps {
    location: string;
}

const SideNavMenu: React.FC<NavProps> = ({location}: NavProps) => {
     const dispatch = useAppDispatch();

     const logoutHandler = async () => {
          await dispatch(logOut());
     }
     return (
        <div className="side-nav-menu">
				<ul className="side-nav-menu-list">
				<li className="side-nav-menu-list-item">
					<NavLink className="nav-link" to="/en_learn">
                        < GoHome className="nav-icon" size={35}/>
                        Home</NavLink>
				</li>
				<li className="side-nav-menu-list-item">
				<NavLink className="nav-link" to="/en_learn/dialogues">
                    { location === 'dialogues' && <><RiDiscussFill className="nav-icon" size={35} /><b>Dialogue</b></> }
                    { location !== 'dialogues' && <><RiDiscussLine className="nav-icon" size={35} /><span>Dialogue</span></> }
                    </NavLink>
				</li>
				<li className="side-nav-menu-list-item">
				<NavLink className="nav-link" to="/en_learn/stories">
                    { location === 'stories' && <><TbBook className="nav-icon" size={35} /><b>Story</b></> }
                    { location !== 'stories' && <><TbBook2 className="nav-icon" size={35} /><span>Story</span></> }
                    </NavLink>
				</li>
                    <li className="side-nav-menu-list-item">
				<NavLink className="nav-link" to="/en_learn/setting">
                    { location === 'setting' && <><TbSettingsFilled className="nav-icon" size={35} /><b>Setting</b></> }
                    { location !== 'setting' && <><TbSettings className="nav-icon" size={35} /><span>Setting</span></> }
                    </NavLink>
				</li>
				</ul>
                    <div className="side-nav-menu-list-item side-logout-button">
                    {/* TODO: Change Link direction to /en_learn/logout */}
                    <NavLink className="nav-link" to="/account/login" onClick={() => logoutHandler()}>
                    Logout <MdLogout className="nav-icon" size={35}/>
                    </NavLink>
                    </div>                              
				</div>
    );
}

export default SideNavMenu;