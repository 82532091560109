import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiPlusSquare } from "react-icons/fi";
import { Input } from "react-chat-elements";

interface PopupProps {
	onCreateStoryHandler: (text: string, lv: number) => Promise<void>;
}

const PopupModal: React.FC<PopupProps> = ({
	onCreateStoryHandler,
}: PopupProps) => {
	const [text, setText] = useState<string>("");
	const [lv, setLv] = useState<number>(0);
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
		setText("");
	};
	const handleShow = () => {
		setOpen(true);
		setText("");
	};

	return (
		<>
			<FiPlusSquare
				className="dialogue-add-button"
				size={35}
				onClick={handleShow}
			/>
			<Modal
				show={open}
				onHide={handleClose}>
				<Modal.Header closeButton>Create New Story</Modal.Header>
				<Modal.Body>
					<span>
						Enter keywords or a story description to create new
						stories.
					</span>
					<Input
						maxlength={100}
						maxHeight={100}
						placeholder="Type something…"
						value={text}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setText(e.target.value)
						}
					/>

					<p>Please select difficulty level of your new story</p>
					<input
						className="story-pop-radio"
						type="radio"
						name="Level 1"
						id="lv0"
						value={0}
						checked={lv === 0}
						onChange={() => setLv(0)}
					/>
					<label htmlFor="lv0">Level 1</label>

					<input
						className="story-pop-radio"
						type="radio"
						name="Level 2"
						id="lv1"
						value={1}
						checked={lv === 1}
						onChange={() => setLv(1)}
					/>
					<label htmlFor="lv1">Level 2</label>

					<input
						className="story-pop-radio"
						type="radio"
						name="Level 3"
						id="lv2"
						value={2}
						checked={lv === 2}
						onChange={() => setLv(2)}
					/>
					<label htmlFor="lv2">Level 3</label>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="button"
						onClick={() => {
							onCreateStoryHandler(text, lv);
							handleClose();
						}}>
						Create
					</Button>
					<Button
						className="button"
						onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default PopupModal;
