import React, { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { DialogueSet } from "../lib/Dialogue";
import { ListGroup } from "react-bootstrap";

interface DialogueListProps {
	isLoading: boolean;
	curIndex: number;
	items: DialogueSet[];
	onItemSelected: (index: number) => Promise<void>;
	get: (key: string | number) => DialogueSet | null;
}

const DialogueListContainer: React.FC<DialogueListProps> = ({
	isLoading,
	curIndex,
	items,
	onItemSelected,
	get,
}: DialogueListProps) => {
	const listElem = useRef<HTMLButtonElement[]>([]);
	const [activeKey, setActiveKey] = useState<number>(curIndex);


	useEffect(() => {
        //Handle Scroll here when opening
		setActiveKey(curIndex);
        setTimeout(() => {
            if (listElem.current[curIndex] != null) {
                listElem.current[curIndex].scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                });
            }
        }, 200);
	}, [curIndex])

    const handleClickToggle = (index: number) => {
		get(index);
	};

	return (
		<ListGroup as="ul">
			{items.map((content: DialogueSet, index: number) => {
				return (
					<ListGroup.Item
                        ref={(el: HTMLButtonElement) => (listElem.current[index] = el)}
						as="button"
                        key={index}
                        className="dialogue-list-item"
						style={{
							backgroundColor:
								activeKey === index
									? "#cfe2ff"
									: "inherit",
						}}
                        onClick={() => {
                            onItemSelected(index);
                            handleClickToggle(index);
                        }}
                        >
                        Dialogue {index}</ListGroup.Item>
				);
			})}
			{isLoading && (
				<Spinner
					style={{alignSelf: "center"}}
					animation="border"
					size="sm"
				/>
			)}
		</ListGroup>
	);
};

export default DialogueListContainer;
