import ReactCardFlip from "react-card-flip";
import { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { StoryGroup, StorySlice, Translation } from "../lib/Story";
import { useAppSelector } from "../store/hooks";

interface CardProps {
	index: number;
	content: StoryGroup;
	translations: Translation;
	isLoading: boolean;
}

const StoryFlipCard: React.FC<CardProps> = ({
	content,
	translations,
	isLoading,
	index,
}: CardProps) => {
	const [isFlipped, setIsFlipped] = useState<boolean>(false);
    const splideRef = useRef<Splide>(null);
	const story_top = useAppSelector(state => state.user.story_top);

	const cardOnClickHandler = () => {
		setIsFlipped(!isFlipped);
	};

    useEffect(() => {
        if (splideRef.current !== null) {
            splideRef.current.go(0);
        }
    }, [index])

	return (
		<Splide
            ref={splideRef}
			options={{ width: "45vw", autoplay: false, }}
			onMoved={() => {
				setIsFlipped(false);
			}}>
			{content?.story.map((slice: StorySlice, s_index: number) => {
				
				return (
					<SplideSlide key={s_index}>
						<ReactCardFlip
							isFlipped={isFlipped}
							flipDirection="vertical">
							<div
								className="story-flip-card"
								onClick={cardOnClickHandler}>
								<h4>
									{slice.sentence}
								</h4>
							</div>
							<div
								className="story-flip-card-exp"
								onClick={cardOnClickHandler}>
								<h4 style={{marginBottom: "20px"}}>
									Top {story_top} difficult words:{" \n"}
								</h4>
								{
									slice.top.slice(0, story_top).map((word: string, w_index: number) => {
										return (
											<h5 key={w_index}>
												{word + ": " + translations[word]}
											</h5>
										)
									})
								}
							</div>
						</ReactCardFlip>
					</SplideSlide>
				);
			})}
		</Splide>
	);
};

export default StoryFlipCard;
