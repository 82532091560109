import React from "react";
import NavigationBar from "./NavigateBar";

const Contact = () => {
	return (
		<div className="page-container">
			<div className="nav">
				<h4 className="page-title">Contact Us</h4>
				<NavigationBar />
			</div>
		</div>
	);
};

export default Contact;
