import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Settings, UserState } from '../../lib/User'

  // Define the initial state using that type
  const initialState: UserState = {
    userName: "",
    uid: "",
    email: "",
    story_top: 2,
    dialogue_new: 3,
    story_new: 1,
    login: false,
  }


  export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      login: (state, actions: PayloadAction<UserState>) => {
        state.userName = actions.payload.userName;
        state.email = actions.payload.email;
        state.uid = actions.payload.uid;
        state.story_top = actions.payload.story_top;
        state.dialogue_new = actions.payload.dialogue_new;
        state.story_new = actions.payload.story_new;
        state.login = true
      },

      logout: (state) => {
        state.uid = "";
        state.userName = "";
        state.email = "";
        state.story_top = 2;
        state.dialogue_new = 3;
        state.story_new = 1;
        state.login = false;
      },

      update_setting: (state, actions: PayloadAction<Settings>) => {
        state.dialogue_new = actions.payload.dialogue_new;
        state.story_top = actions.payload.story_top;
        state.story_new = actions.payload.story_new;
      }

    },
  })
  
  export const { login, logout, update_setting } = userSlice.actions
  
  export default userSlice.reducer