import { useAppDispatch, useAppSelector } from "../store/hooks";
import { auth } from "../lib/firebase";
import { login, logout, update_setting } from "../store/Reducers/UserSlice";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import { Action, Dispatch } from "redux";
import { Settings, UserDBState } from "../lib/User";

interface RegisterProps {
	username: string;
	email: string;
	password: string;
}

interface LoginProps {
	email: string;
	password: string;
}

interface RetrieveProps {
	uid: string;
}

export const signUp = ({ username, email, password }: RegisterProps) => {
	return async (dispatch: Dispatch<Action>) => {
		const userCredential = await createUserWithEmailAndPassword(
			auth,
			email,
			password
		);
		const user = userCredential.user;
		const uid = user.uid;

		const uri = "/register";
		const header = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				uid,
				email,
				username,
				role: "user",
			}),
		};

		const response = await fetch(uri, header);

		dispatch(
			login({
				uid,
				email,
				userName: username,
				story_top: 3,
				dialogue_new: 2,
				story_new: 1,
				login: true,
			})
		);
	};
};

export const signIn = ({ email, password }: LoginProps) => {
	return async (dispatch: Dispatch<Action>) => {
		const userCredential = await signInWithEmailAndPassword(
			auth,
			email,
			password
		);
		const user = userCredential.user;
		const uid = user.uid;

		const uri = "/login";
		const header = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				uid,
			}),
		};

		const response = await fetch(uri, header);
		const data = (await response.json()) as UserDBState;

		dispatch(
			login({
				uid,
				email: data.email,
				userName: data.userName,
				story_top: data.story_top_num,
				dialogue_new: data.new_dialogue_num,
				story_new: data.story_new_num,
				login: true,
			})
		);
	};
};

// retrieve user that has token reserve in app
export const retrieveUser = ({uid}: RetrieveProps) => {
    return async (dispatch: Dispatch<Action>) => {
        const uri = "/login";
		const header = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				uid,
			}),
		};

        const response = await fetch(uri, header);
		const data = (await response.json()) as UserDBState;

		dispatch(
			login({
				uid,
				email: data.email,
				userName: data.userName,
				story_top: data.story_top_num,
				dialogue_new: data.new_dialogue_num,
				story_new: data.story_new_num,
				login: true,
			})
		);
    } 
}

export const logOut = () => {
    return async (dispatch: Dispatch<Action>) => {
        await signOut(auth); 

        dispatch(logout());
    }
}


// Update user settings to database
export const userSetting = (uid: string, settings: Settings) => {
	return async (dispatch: Dispatch<Action>) => {
		const uri = "/update_setting"
		const header = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				"uid": uid,
				"new_dialogue_num": Number(settings.dialogue_new),
				"story_top_num": Number(settings.story_top),
				"new_story_num": Number(settings.story_new),
			}),
		};

		try {
			const response = await fetch(uri, header);

			dispatch(update_setting(settings));
			return 200;
		} catch (error) {
			console.log(error);
			return 400;
		}
	}
}