import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiPlusSquare } from "react-icons/fi";
import { Input } from "react-chat-elements";

interface PopupProps {
	onCreateDialogueHandler: (text: string) => Promise<void>;
}

const PopupModal: React.FC<PopupProps> = ({
	onCreateDialogueHandler,
}: PopupProps) => {
	const [text, setText] = useState<string>("");
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
		setText("");
	};
	const handleShow = () => {
		setOpen(true);
		setText("");
	};

	return (
		<>
			<FiPlusSquare
				className="dialogue-add-button"
				size={35}
				onClick={handleShow}
			/>
			<Modal
				show={open}
				onHide={handleClose}>
				<Modal.Header closeButton>Create New Dialogue</Modal.Header>
				<Modal.Body>
					<span>
						Enter a dialogue scene for creating new dialogues.
					</span>
					<Input
						maxlength={100}
						maxHeight={100}
						placeholder="Type something…"
						value={text}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setText(e.target.value)
						}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="button"
						onClick={() => {
							onCreateDialogueHandler(text);
							handleClose();
						}}>
						Create
					</Button>
					<Button
						className="button"
						onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default PopupModal;
