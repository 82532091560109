import { useAppDispatch } from "../store/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import NavigationBar from "./NavigateBar";
import { signIn } from "../actions/auth";
import { useState } from "react";

interface props {
	isAuthentic: boolean;
}

interface IFormInput {
	email: string;
	password: string;
}

const Login: React.FC<props> = ({ isAuthentic }: props) => {
    const [errorMsg, setErrorMsg] = useState<string>("");
    const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInput>();
	const onSubmit: SubmitHandler<IFormInput> = async (data) => {
		const email = data.email;
		const password = data.password;
        try {
            const action = signIn({email, password});
            await dispatch(action)
			navigate("/en_learn");
		} catch (error) {
			console.log(error);

			setErrorMsg("User Already Exists.");

			setTimeout(() => {
				setErrorMsg("");
			}, 2000);
		}
	};

	const navigate = useNavigate();

	return (
		<>
			<NavigationBar />
			<div className="login_form">
				{!isAuthentic ? (
                    <>
					<form
						className="login_form"
						onSubmit={handleSubmit(onSubmit)}>
						<label className="login_label">Email</label>
						<input
							className="login_input"
							{...register("email", {
								required: "Email is required to login",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message:
										"Invalid Email address. Please enter a valid Email.",
								},
							})}
						/>
						{errors?.email && <p className="invalid_p">{errors.email.message}</p>}

						<label className="login_label">Password</label>
						<input
							className="login_input"
							{...register("password", {
								required: "Password is required to login",
								minLength: {
									value: 6,
									message:
										"Password is required to have at lease 6 characters.",
								},
							})}
						/>
                        {errors?.password && <p className="invalid_p">{errors.password.message}</p>}

						<div className="login_buttons">
							<input
								className="login_submit"
								style={{
									justifySelf: "start",
									marginRight: "auto",
								}}
								type="submit"
								value="Login"
							/>

							<button
								className="login_submit"
								style={{
									justifySelf: "end",
									marginLeft: "auto",
								}}
								type="button"
								onClick={() => navigate("/account/register")}>
								{" "}
								Register{" "}
							</button>
						</div>
					</form>
                    {errorMsg && <Alert variant="warning">{errorMsg}</Alert>}
                    </>
				) : (
					<Navigate
						replace
						to="/en_learn"
					/>
				)}
			</div>
		</>
	);
};

export default Login;
