import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { NavLink, useNavigate } from "react-router-dom";

const NavigationBar = () => {
	const navigate = useNavigate();

	return (
		<div className="nav">
			<h4 className="page-title" onClick={() => navigate("/en_learn")}>English Learner</h4>
			<div className="nav-bar">
				<ButtonGroup className="mb-2">
					<Button
						style={{ borderColor: "transparent" }}
						variant="outline-light"
						size="sm">
						<NavLink
							className="nav-link"
							to="/">
							Home
						</NavLink>
					</Button>
					<Button
						style={{ borderColor: "transparent" }}
						variant="outline-light"
						size="sm">
						<NavLink
							className="nav-link"
							to="/en_learn">
							English Learner
						</NavLink>
					</Button>
					<Button
						style={{ borderColor: "transparent" }}
						variant="outline-light"
						size="sm">
						<NavLink
							className="nav-link"
							to="/contact">
							Contact Us
						</NavLink>
					</Button>
				</ButtonGroup>
			</div>
		</div>
	);
};

export default NavigationBar;
