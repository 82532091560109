import React from "react";
import { useEffect, useCallback, useReducer, useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import NavigationBar from "./NavigateBar";
import Button from "react-bootstrap/Button";
import { TbBook2, TbSettings } from "react-icons/tb";
import { RiDiscussLine } from "react-icons/ri";
import { MdLogout } from "react-icons/md";
import { useAppDispatch } from "../store/hooks";
import { logOut } from "../actions/auth";

interface props {
	isAuthentic: boolean;
}

const EnLearnHome: React.FC<props> = ({ isAuthentic }: props) => {
	const dispatch = useAppDispatch();

     const logoutHandler = async () => {
          await dispatch(logOut());
     }
	return (
		<>
			{isAuthentic ? (
				<div className="page-container">
					<NavigationBar />
					<div className="content-container-home">
						<div className="vertical-button">
							<h3>Select type of content you want to learn</h3>

							<Button
								variant="outline-secondary"
								className="type-select-button"
								size="lg">
								<NavLink
									className="nav-link"
									to="/en_learn/dialogues">
									<RiDiscussLine
										className="nav-icon"
										size={35}
									/>
									Dialogue
								</NavLink>
							</Button>
							<Button
								variant="outline-secondary"
								className="type-select-button"
								size="lg">
								<NavLink
									className="nav-link"
									to="/en_learn/stories">
									<TbBook2
										className="nav-icon"
										size={35}
									/>
									Story
								</NavLink>
							</Button>

							<Button
								variant="outline-secondary"
								className="type-select-button"
								size="lg">
								<NavLink
									className="nav-link"
									to="/en_learn/setting">
									<TbSettings
										className="nav-icon"
										size={35}
									/>
									Setting
								</NavLink>
							</Button>

							<MdLogout
								style={{
									alignSelf: "end",
									marginTop: "20px",
									marginRight: "40px",
								}}
								className="type-select-button-logout"
								size={35}
								onClick={() => logoutHandler()}
							/>
						</div>
					</div>
				</div>
			) : (
				<Navigate
					replace
					to="/account/login"
				/>
			)}
		</>
	);
};

export default EnLearnHome;
